<template>
    <div class="content-strategy-modal blog-modal approving-node">
        <div class="blog-modal__modal import-modal">
            <button class="btn-link social-modal__exit btn-hover" @click.prevent="onClose()">
                <i class="fas fa-times"></i>Close
            </button>
            <div class="import-modal__wrap">
                <h1>Approve All</h1>

                <div class="modal-content">
                    <div class="modal-content-wrap">
                        <div
                            v-for="(node, index) in allApprovingNodes"
                            :key="node.id || index"
                            class="modal-row"
                        >
                            <div class="modal-col-header">Node</div>
                            <div class="modal-col-text">
                                <input
                                    type="text"
                                    :value="node.fieldGroups.title.fields.title.value"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btn-row">
                    <button class="btn-save" @click.prevent="handleSubmit()">
                        <i class="fa-solid fa-circle-check"></i>Approve & Create
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            formSubmitted: false
        };
    },
    computed: {
        ...mapGetters("client", ["getContentStrategy", "getClientUsers", "getClientData"]),
        allApprovingNodes() {
            const nodesList = JSON.parse(JSON.stringify(this.getContentStrategy.nodes));
            const nodes2 = nodesList.filter(node => node.approved === false);

            if (nodes2.length === 0) {
                return [];
            } else {
                const nodes = nodes2.map(node => {
                    node.isImported = true;
                    node.fieldGroups = {
                        assign: node.fieldGroup_Assign || '',
                        author: node.fieldGroup_Author || '',
                        created: node.fieldGroup_Created || '',
                        deadline: node.fieldGroup_Deadline || '',
                        details: node.fieldGroup_Details || '',
                        format: node.fieldGroup_Format || '',
                        intent: node.fieldGroup_Intent || '',
                        keyword: node.fieldGroup_Keyword || '',
                        research: node.fieldGroup_Research || '',
                        status: node.fieldGroup_Status || '',
                        title: node.fieldGroup_Title|| '',
                    };
                    return node;
                });
                console.log('STEP1', nodes);
                return nodes;
            }
        }
    },
    methods: {
        onClose() {
            this.$store.commit('client/setContentStrategy', { prop: 'isApprovingAll', val: false });
            this.$store.commit('client/setContentStrategy', { prop: 'currentNode', val: false });
        },
        async handleSubmit() {
            this.formSubmitted = true;
            // Perform validation if needed
            const allValid = this.allApprovingNodes.every(node => node.fieldGroups.title.fields.title.value);

            if (!allValid) {
                // Handle validation errors (e.g., show a message)
                alert('not valid');
                return;
            }

            if(this.allApprovingNodes.length > 0){
                this.$store.commit('client/setContentStrategy', { prop: 'approvedNodes', val: this.allApprovingNodes });
                this.$store.commit('client/setContentStrategy', { prop: 'isImporting', val: true });
                this.$store.commit('client/setContentStrategy', { prop: 'isApprovingAll', val: false });
                this.$router.push({
                    name: 'Client Content',
                    query: {
                        format: 'false',
                        list: 'true',
                    }
                });
            }else{
                alert('Sorry they are already approved or there is no nodes to approve')
                this.$store.commit('client/setContentStrategy', { prop: 'isApprovingAll', val: false });
            }
        }
    }
};
</script>

<style lang="scss">
@import "./../../../src/assets/scss/components/content-strategy.scss";
.approving-node .modal-row {
    pointer-events: none !important;
}
.approving-node .btn-save {
    width: 200px !important;
    max-width: 200px !important;
}
</style>

<template>
    <div class="content-strategy-modal blog-modal approving-node">
        <div class="blog-modal__modal import-modal">
            <button class="btn-link social-modal__exit btn-hover" @click.prevent="onClose()">
                <i class="fas fa-times"></i>Close
            </button>
            <div class="import-modal__wrap">
                <h1>Approve This</h1>

                <div class="modal-content">
                    <div class="modal-content-wrap">

                        <!-- Title Field -->
                        <div class="modal-row" :class="{'has-error': formSubmitted && !node.fieldGroups.title.fields.title.value}">
                            <div class="modal-col-header">Title</div>
                            <div class="modal-col-text">
                                <input type="text" 
                                    v-model="node.fieldGroups.title.fields.title.value" 
                                    placeholder="Enter title here" 
                                    :class="{'input-error': formSubmitted && !node.fieldGroups.title.fields.title.value}">
                            </div>
                        </div>

                        <div class="modal-row large-margin-bottom">
                            <div class="modal-col-header">Details</div>
                            <div class="modal-col-text">
                                <textarea v-model="node.fieldGroups.details.fields.details.value" placeholder="Enter details here"></textarea>
                            </div>
                        </div>

                        <div class="modal-row">
                            <div class="modal-col-header">Assign</div>
                            <div class="modal-col-text">
                                <div class="styled-select">
                                    <VueNextSelect 
                                    :key="selectKey" 
                                    v-model="node.fieldGroups.assign.fields.assign.value" 
                                    :options="getClientUsers" 
                                    label-by="username" 
                                    close-on-select
                                    :placeholder="this.node.fieldGroups.assign.fields.assign.value"
                                    />
                                </div>   
                            </div>
                        </div>

                        <div class="modal-row">
                            <div class="modal-col-header">Deadline</div>
                            <div class="modal-col-text">
                            	<div class="deadline form-item datepicker">
                                    <Datepicker v-model="node.fieldGroups.deadline.fields.deadline.value" class="form-control" dark autoApply format="dd/MM/yyyy HH:mm"></Datepicker>
                                </div>
                            </div>
                        </div>


                        <div class="modal-row">
                            <div class="modal-col-header">Keyword</div>
                            <div class="modal-col-text">
                                <input type="text" 
                                    v-model="node.fieldGroups.keyword.fields.keyword.value" 
                                    placeholder="Enter keyword here">
                            </div>
                        </div>

                        <!-- Format Field -->
                        <div class="modal-row" :class="{'has-error': formSubmitted && !node.fieldGroups.format.fields.format.value}">
                            <div class="modal-col-header">Format</div>
                            <div class="modal-col-text">
                                <div class="styled-select">
                                    <VueNextSelect 
                                        :key="selectKey1" 
                                        v-model="node.fieldGroups.format.fields.format.value" 
                                        :options="formats" 
                                        label-by="format" 
                                        close-on-select
                                        :class="{'input-error': formSubmitted && !node.fieldGroups.format.fields.format.value}"
                                        :placeholder="this.node.fieldGroups.format.fields.format.value"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="modal-row">
                            <div class="modal-col-header">Intent</div>
                            <div class="modal-col-text">
                                <input type="text" v-model="node.fieldGroups.intent.fields.intent.value" placeholder="Enter intent here">
                            </div>
                        </div>

                        <div class="modal-row">
                            <div class="modal-col-header">Research</div>
                            <div class="modal-col-text">
                                <input type="text" v-model="node.fieldGroups.research.fields.research.value" placeholder="Enter intent here">
                            </div>
                        </div>

                        <!-- Status Field -->
                        <!-- <div class="modal-row" :class="{'has-error': formSubmitted && !node.fieldGroups.status.fields.status.value}">
                            <div class="modal-col-header">Status</div>
                            <div class="modal-col-text">
                                <div class="styled-select">
                                    <VueNextSelect 
                                        :key="selectKey" 
                                        v-model="node.fieldGroups.status.fields.status.value" 
                                        :options="statusOptions" 
                                        label-by="status" 
                                        close-on-select
                                        :class="{'input-error': formSubmitted && !node.fieldGroups.status.fields.status.value}"
                                        :placeholder="this.node.fieldGroups.status.fields.status.value"
                                    />
                                </div>
                            </div>
                        </div> -->

                        <div class="modal-row">
                            <div class="modal-col-header">Author</div>
                            <div class="modal-col-text">
                                <div class="styled-select">
                                    <VueNextSelect 
                                        :key="selectKey" 
                                        v-model="node.fieldGroups.author.fields.author.value" 
                                        :options="getClientUsers" 
                                        label-by="username" 
                                        close-on-select
                                        :placeholder="this.node.fieldGroups.author.fields.author.value"
                                    />
                                </div> 
                            </div>
                        </div>

                        <!-- <div class="modal-row">
                            <div class="modal-col-header">Created</div>
                            <div class="modal-col-text">
                                <div class="deadline form-item datepicker">
                                    <Datepicker v-model="node.fieldGroups.created.fields.created.value" class="form-control" dark autoApply format="dd/MM/yyyy HH:mm"></Datepicker>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>

                <div class="btn-row">
                    <button class="btn-save" @click.prevent="handleSubmit()">
                        <i class="fa-solid fa-circle-check"></i>Approve & Create
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueNextSelect from 'vue-next-select';
import Datepicker from "vue3-date-time-picker";
import Node from '../../services/contentNodes';

export default {
    components: {
        VueNextSelect,
        Datepicker, // Register the date picker component
    },
    mounted(){
        console.log('getClientUsers', this.getClientUsers);
        console.log('getClientData', this.getClientData);
        const foundNode = this.getContentStrategy.nodes.find(node => node.id === this.getContentStrategy.currentNode)
        this.mapImportedNode(JSON.parse(JSON.stringify(foundNode)))
        console.log('EDIT NODE DATA', this.node);
    },
    watch:{
        'node.fieldGroups': {
            async handler(val) {
                console.log('changedddd', val);
            },
            deep: true
        },
    },
    data() {
        return {
            formats: [{format: 'Blog'}, {format: 'Social'}],
            statusOptions: [
                {status: 'Title created'},
                {status: 'Assigned'},
                {status: 'Pending review'},
                {status: 'Approved'},
                {status: 'Rejected'},
                {status: 'Published'},
                {status: 'Archived'},
            ],
            selectKey: 0,
            selectKey1: 0,
            node: new Node(),
            formSubmitted: false, // Add this flag to handle validation
        };
    },
    computed: {
        ...mapGetters("client", ["getContentStrategy", "getClientUsers", "getClientData"]),
    },
    methods: {
        mapImportedNode(nodeData){
            console.log('map imported node data', nodeData);
            this.node.id = nodeData.id;
            this.node.fieldGroups = {
			assign: nodeData.fieldGroup_Assign,
			author: nodeData.fieldGroup_Author,
			created: nodeData.fieldGroup_Created,
			deadline: nodeData.fieldGroup_Deadline,
			details: nodeData.fieldGroup_Details,
			format: nodeData.fieldGroup_Format,
			intent: nodeData.fieldGroup_Intent,
			keyword: nodeData.fieldGroup_Keyword,
			research: nodeData.fieldGroup_Research,
			status: nodeData.fieldGroup_Status,
			title: nodeData.fieldGroup_Title,
		    }
            console.log('the node data', this.node);
        },
        mapSelectFields(){
            console.log('mapSelectedFields', this.node.fieldGroups);
            if(this.node.fieldGroups.status.fields.status.value && this.node.fieldGroups.status.fields.status.value.status){
                this.node.fieldGroups.status.fields.status.value = this.node.fieldGroups.status.fields.status.value.status;
            }else if(this.node.fieldGroups.status.fields.status.value === undefined){
                this.node.fieldGroups.status.fields.status.value = '';
            }
            if(this.node.fieldGroups.format.fields.format.value && this.node.fieldGroups.format.fields.format.value.format){
                this.node.fieldGroups.format.fields.format.value = this.node.fieldGroups.format.fields.format.value.format;
            }else if(this.node.fieldGroups.format.fields.format.value === undefined){
                this.node.fieldGroups.format.fields.format.value = ''
            }
            if(this.node.fieldGroups.author.fields.author.value && this.node.fieldGroups.author.fields.author.value.username){
                this.node.fieldGroups.author.fields.author.value =  this.node.fieldGroups.author.fields.author.value.username
            }else if(this.node.fieldGroups.author.fields.author.value === undefined){
                this.node.fieldGroups.author.fields.author.value = ''
            }
            if(this.node.fieldGroups.assign.fields.assign.value && this.node.fieldGroups.assign.fields.assign.value.username){
                this.node.fieldGroups.assign.fields.assign.value = this.node.fieldGroups.assign.fields.assign.value.username;
            }else if(this.node.fieldGroups.assign.fields.assign.value === undefined){
                this.node.fieldGroups.assign.fields.assign.value = ''
            }
            console.log('mapSelectFields:', [
                this.node.fieldGroups.status.fields.status.value,
                this.node.fieldGroups.format.fields.format.value,
                this.node.fieldGroups.author.fields.author.value,
                this.node.fieldGroups.assign.fields.assign.value
            ])
        },
        onClose() {
            this.$store.commit('client/setContentStrategy', {prop: 'isApproving', val: false});
            this.$store.commit('client/setContentStrategy', {prop: 'currentNode', val: false});
        },
        async handleSubmit() {
            
            this.$store.commit('client/setContentStrategy', {prop: 'isApproving', val: false})
                const approvedNodes = JSON.parse(JSON.stringify(this.getContentStrategy.approvedNodes));
                approvedNodes.push(this.node);
                this.$store.commit('client/setContentStrategy', {prop: 'approvedNodes', val: approvedNodes})
                this.$store.commit('client/setContentStrategy', {prop: 'isImporting', val: true})
                // Navigate to the 'content' route with query parameters format=false and list=true
                this.$router.push({
                    name: 'Client Content',
                    query: {
                        format: 'false',
                        list: 'true',
                    }
                });
            
        }
    }
};
</script>


<style lang="scss">
@import "./../../../src/assets/scss/components/content-strategy.scss";
.approving-node .modal-row{
    pointer-events: none !important;
}
.approving-node .btn-save{
    width: 200px !important;
    max-width: 200px !important;
}
</style>
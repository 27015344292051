<template>
    <div class="content-strategy-modal blog-modal">
        <div class="blog-modal__modal import-modal">
            <button class="btn-link social-modal__exit btn-hover" @click.prevent="onClose()">
                <i class="fas fa-times"></i>Close
            </button>
            <div class="import-modal__wrap">
                <h1>Create This</h1>

                <div class="modal-content">
                    <div class="modal-content-wrap">

                        <!-- Title Field -->
                        <div class="modal-row" :class="{'has-error': formSubmitted && !node.fieldGroups.title.fields.title.value}">
                            <div class="modal-col-header">Title</div>
                            <div class="modal-col-text">
                                <input type="text" 
                                    v-model="node.fieldGroups.title.fields.title.value" 
                                    placeholder="Enter title here" 
                                    :class="{'input-error': formSubmitted && !node.fieldGroups.title.fields.title.value}">
                            </div>
                        </div>

                        <div class="modal-row large-margin-bottom">
                            <div class="modal-col-header">Details</div>
                            <div class="modal-col-text">
                                <textarea v-model="node.fieldGroups.details.fields.details.value" placeholder="Enter details here"></textarea>
                            </div>
                        </div>

                        <div class="modal-row">
                            <div class="modal-col-header">Assign</div>
                            <div class="modal-col-text">
                                <div class="styled-select">
                                    <VueNextSelect 
                                    :key="selectKey" 
                                    v-model="node.fieldGroups.assign.fields.assign.value" 
                                    :options="getClientUsers" 
                                    label-by="username" 
                                    close-on-select
                                    />
                                </div>   
                            </div>
                        </div>

                        <div class="modal-row">
                            <div class="modal-col-header">Deadline</div>
                            <div class="modal-col-text">
                            	<div class="deadline form-item datepicker">
                                    <Datepicker v-model="node.fieldGroups.deadline.fields.deadline.value" class="form-control" dark autoApply format="dd/MM/yyyy HH:mm"></Datepicker>
                                </div>
                            </div>
                        </div>


                        <div class="modal-row">
                            <div class="modal-col-header">Keyword</div>
                            <div class="modal-col-text">
                                <input type="text" 
                                    v-model="node.fieldGroups.keyword.fields.keyword.value" 
                                    placeholder="Enter keyword here">
                            </div>
                        </div>

                        <!-- Format Field -->
                        <div class="modal-row" :class="{'has-error': formSubmitted && !node.fieldGroups.format.fields.format.value}">
                            <div class="modal-col-header">Format</div>
                            <div class="modal-col-text">
                                <div class="styled-select">
                                    <VueNextSelect 
                                        :key="selectKey1" 
                                        v-model="node.fieldGroups.format.fields.format.value" 
                                        :options="formats" 
                                        label-by="format" 
                                        close-on-select
                                        :class="{'input-error': formSubmitted && !node.fieldGroups.format.fields.format.value}"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="modal-row">
                            <div class="modal-col-header">Intent</div>
                            <div class="modal-col-text">
                                <input type="text" v-model="node.fieldGroups.intent.fields.intent.value" placeholder="Enter intent here">
                            </div>
                        </div>

                        <div class="modal-row">
                            <div class="modal-col-header">Research</div>
                            <div class="modal-col-text">
                                <input type="text" v-model="node.fieldGroups.research.fields.research.value" placeholder="Enter intent here">
                            </div>
                        </div>

                        <!-- Status Field -->
                        <!-- <div class="modal-row" :class="{'has-error': formSubmitted && !node.fieldGroups.status.fields.status.value}">
                            <div class="modal-col-header">Status</div>
                            <div class="modal-col-text">
                                <div class="styled-select">
                                    <VueNextSelect 
                                        :key="selectKey" 
                                        v-model="node.fieldGroups.status.fields.status.value" 
                                        :options="statusOptions" 
                                        label-by="status" 
                                        close-on-select
                                        :class="{'input-error': formSubmitted && !node.fieldGroups.status.fields.status.value}"
                                    />
                                </div>
                            </div>
                        </div> -->

                        <div class="modal-row">
                            <div class="modal-col-header">Author</div>
                            <div class="modal-col-text">
                                <div class="styled-select">
                                    <VueNextSelect 
                                        :key="selectKey" 
                                        v-model="node.fieldGroups.author.fields.author.value" 
                                        :options="getClientUsers" 
                                        label-by="username" 
                                        close-on-select
                                    />
                                </div> 
                            </div>
                        </div>
<!-- 
                        <div class="modal-row">
                            <div class="modal-col-header">Created</div>
                            <div class="modal-col-text">
                                <div class="deadline form-item datepicker">
                                    <Datepicker v-model="node.fieldGroups.created.fields.created.value" class="form-control" dark autoApply format="dd/MM/yyyy HH:mm"></Datepicker>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>

                <div class="btn-row">
                    <div class="content-strategy-error-modal"  v-if="errorMessage">
                        <h3>{{ errorMessage }}</h3>
                    </div>
                    <button class="btn-save" @click.prevent="handleSubmit()">
                        <i class="fa-solid fa-circle-check"></i>Create
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueNextSelect from 'vue-next-select';
import Datepicker from "vue3-date-time-picker";
import Node from '../../services/contentNodes';

export default {
    components: {
        VueNextSelect,
        Datepicker, // Register the date picker component
    },
    data() {
        return {
            formats: [{format: 'Blog'}, {format: 'Social'}],
            statusOptions: [
                {status: 'Title created'},
                {status: 'Assigned'},
                {status: 'Pending review'},
                {status: 'Approved'},
                {status: 'Rejected'},
                {status: 'Published'},
                {status: 'Archived'},
            ],
            selectKey: 0,
            selectKey1: 0,
            node: new Node(),
            formSubmitted: false, // Add this flag to handle validation
            errorMessage: ''
        };
    },
    computed: {
        ...mapGetters("client", ["getContentStrategy", "getClientUsers", "getClientData"]),
    },
    watch:{
        'node.fieldGroups': {
            async handler(val) {
                console.log('changedddd', val);
            },
            deep: true
        },
    },
    mounted(){
        this.node.fieldGroups.status.fields.status.value = 'Title created';

        if(this.getContentStrategy.isDayView){
            console.log('=== is viewing day ====', [this.getContentStrategy.isDayView, this.getContentStrategy.exactDate]);
            this.node.fieldGroups.deadline.fields.deadline.value = this.getContentStrategy.exactDate;
        }else{
            const month = this.getContentStrategy.month; // e.g., 'september'
            const year = this.getContentStrategy.year; // e.g., '2024'
            // Create a map for month names to month numbers (0-based index in JavaScript Date object)
            const monthMap = {
                january: 0,
                february: 1,
                march: 2,
                april: 3,
                may: 4,
                june: 5,
                july: 6,
                august: 7,
                september: 8,
                october: 9,
                november: 10,
                december: 11
            };
            // Get the corresponding month number from the month name
            const monthNumber = monthMap[month.toLowerCase()];
            // Create the date object for the 1st day of the given month and year
            const dateObj = new Date(year, monthNumber, 1);
            this.node.fieldGroups.deadline.fields.deadline.value = dateObj;
        }
        console.log('=== DEADLINE HAS BEEN UPDATED ====', this.node.fieldGroups.deadline.fields.deadline.value)
        console.log('the status is!', this.node.fieldGroups.status.fields.status.value);
    },
    methods: {
        mapSelectFields(){
            console.log('mapSelectedFields', this.node.fieldGroups);
            if(this.node.fieldGroups.status.fields.status.value && this.node.fieldGroups.status.fields.status.value.status){
                this.node.fieldGroups.status.fields.status.value = this.node.fieldGroups.status.fields.status.value.status;
            }else{
                this.node.fieldGroups.status.fields.status.value = ''
            }
            if(this.node.fieldGroups.format.fields.format.value && this.node.fieldGroups.format.fields.format.value.format){
                this.node.fieldGroups.format.fields.format.value = this.node.fieldGroups.format.fields.format.value.format;
            }else{
                this.node.fieldGroups.format.fields.format.value = ''
            }
            if(this.node.fieldGroups.author.fields.author.value && this.node.fieldGroups.author.fields.author.value.username){
                this.node.fieldGroups.author.fields.author.value =  this.node.fieldGroups.author.fields.author.value.username
            }else{
                this.node.fieldGroups.author.fields.author.value = ''
            }
            if(this.node.fieldGroups.assign.fields.assign.value && this.node.fieldGroups.assign.fields.assign.value.username){
                this.node.fieldGroups.assign.fields.assign.value = this.node.fieldGroups.assign.fields.assign.value.username;
            }else{
                this.node.fieldGroups.assign.fields.assign.value = ''
            }
            console.log('mapSelectFields:', [
                this.node.fieldGroups.status.fields.status.value,
                this.node.fieldGroups.format.fields.format.value,
                this.node.fieldGroups.author.fields.author.value,
                this.node.fieldGroups.assign.fields.assign.value
            ])
        },
        onClose() {
            this.$store.commit('client/setContentStrategy', {prop: 'isAdding', val: false});
            this.$store.commit('client/setContentStrategy', {prop: 'currentNode', val: false});
        },
        async handleSubmit() {
            // Set formSubmitted to true when the user attempts to submit the form
            this.formSubmitted = true;

            // Check if required fields are filled
            const isTitleValid = !!this.node.fieldGroups.title.fields.title.value;
            const isFormatValid = !!this.node.fieldGroups.format.fields.format.value;

            if (!isTitleValid || !isFormatValid) {
                // Show an alert if any of the required fields are missing
                let missingFields = [];

                if (!isTitleValid) missingFields.push('Title');
                if (!isFormatValid) missingFields.push('Format');

                // Alert the user which fields are missing
                this.errorMessage = `Form submission prevented. Missing required fields: ${missingFields.join(', ')}`;
                return;
            }

            this.node.fieldGroups.status.fields.status.value = 'Title created'

            // If validation passes, proceed with the submission
            try {
                this.mapSelectFields();
                await this.$store.dispatch('client/createOrUpdateContentStrategyNode', {node: this.node});
                console.log('Node created/updated successfully');
                await this.$store.dispatch('client/getAllContentStrategyNodes');
                this.$store.commit('client/setContentStrategy', {prop: 'isAdding', val: false});
            } catch (error) {
                console.log('handleSubmit content strategy error', error);
            }
        }
    }
};
</script>

<style lang="scss">
@import "./../../../src/assets/scss/components/content-strategy.scss";

.has-error .modal-col-text input,
.has-error .modal-col-text .styled-select {
    border: 2px solid red;
}

.input-error {
    border: 2px solid red !important;
}
</style>

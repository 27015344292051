<template>
    <div class="comments-list" :class="{active : this.getOpenCommentPanel}">

      <div v-if="this.getContentStrategy.isShowing" class="btn-close btn-strategy-close">
        <div @click.prevent="handleContentStrategyClose()"><i class="fas fa-times" aria-hidden="true"></i>Close</div>
      </div>

      <!-- ##################################################### ADD COMMENT ##################################################### -->

      <div class="comments-list__add-comment">
        <textarea rows="3" @input="onCommentInputKeyUp(`addCommentInput`, $event)" @click="this.$store.commit('client/setCreateComment', true)" type="text" placeholder="Write a comment" id="addCommentInput"></textarea>
        <div class="comment__btn-wrap">
          <a class="btn-mention" @click.prevent="onTagUser('addCommentInput')"><i class="fa-solid fa-at"></i>Tag User</a>
          <a @click.prevent="onCancel()">Cancel</a>
          <a @click.prevent="onAddComment()">Submit</a>
        </div>
      </div>

      <!-- ##################################################### ADD COMMENT ##################################################### -->
      
      <div class="comments-list--all-items">
        
        <div class="no-comments-message" v-if="nestedComments.length === 0">
          <i class="fa-solid fa-message"></i>
          <h2>Start a discussion or make a note</h2>
        </div>

        <ul>

      <!-- ##################################################### PARENT COMMENTS ##################################################### -->

          <li v-for="item in nestedComments" v-bind:key="item"  class="comment" :comment-id="item.id" :class="{comment__resolved : item.resolved === true && onlyShowResolved === false}" @click.prevent="onFocusComment(item.id)">
            <div class="comment__user">
              <div class="comment-button__header">
                <div class="comment_user-info">
                  <div class="comment__avatar">
                      <img :src="item.avatarUrl">
                  </div>
                  <div>
                    <p class="comment__username">{{ item.user }}</p>
                    <p class="comment__created">{{ formatDate(item.createdAt) }}</p>
                  </div>
                </div>
                  <div class="comment__menu">
                    <i class="fa-solid fa-ellipsis-vertical" @click.prevent="onOpenMenu(item.id)"></i>
                    <div class="menu">
                        <ul>
                            <li v-if="item.resolved === false" @click.prevent="onResolve(item.id)" class="comment__resolve-icon">Resolve</li>
                            <li @click.prevent="this.$store.commit('client/setEditSingleComment', item.id);" v-if="item.resolved === false">Edit</li>
                            <li @click.prevent="onDelete(item.id)">Delete</li>
                        </ul>
                    </div>
                  </div>
              </div>
              <div class="comment__input-wrap">
                <div v-html="item.html" class="comment__content" v-if="isEditingThisComment(item.id) === false"></div>
                  <textarea @input="onCommentInputKeyUp('editMainCommentInput', $event)" class="comment-bubble__comment" type="text" id="editMainCommentInput" v-model="item.comment" v-if="isEditingThisComment(item.id)"></textarea>
                </div>

                <span class="comment__resolved-text" v-if="item.resolved"><i class="fa-solid fa-circle-check"></i>Resolved</span>

                <div v-if="isEditingThisComment(item.id) === true" class="comment__btn-wrap--edit comment__btns-wrap comment__btns-wrap-edit" style="padding-left: 0; padding-right: 0; padding-top: 15px;">
                  <a class="btn-mention" @click.prevent="onTagUser(`editMainCommentInput`)"><i class="fa-solid fa-at"></i>Tag User</a>
                  <a @click.prevent="onCancel()">Cancel</a>
                  <a @click.prevent="onEditComment()">Submit</a>
                </div>

              </div>

            <!-- ##################################################### CHILD COMMENTS ##################################################### -->

                <template v-for="item2 in item.replies" v-bind:key="item2">

                  <hr>

                  <div class="comment__user" :comment-id="item2.id">
                      <div class="comment-button__header">
                        <div class="comment_user-info">
                          <div class="comment__avatar">
                              <img :src="item2.avatarUrl">
                          </div>
                          <div>
                            <p class="comment__username">{{ item2.user }}</p>
                            <p class="comment__created">{{ formatDate(item2.createdAt) }}</p>
                          </div>
                        </div>
                          <div class="comment__menu" v-if="item.resolved === false">
                            <i class="fa-solid fa-ellipsis-vertical" @click.prevent="onOpenMenu(item2.id)"></i>
                            <div class="menu">
                                <ul>
                                  <li @click.prevent="this.$store.commit('client/setEditSingleComment', item2.id);">Edit</li>
                                  <li @click.prevent="onDelete(item2.id)">Delete</li>
                                </ul>
                            </div>
                          </div>
                      </div>
                      <div class="comment__input-wrap">
                        <div v-html="item2.html" class="comment__content" v-if="isEditingThisComment(item2.id) === false"></div>
                          <textarea  @input="onCommentInputKeyUp('editChildComment--' + item2.id, $event)" class="comment-bubble__comment" type="text" :id="`editChildComment--${item2.id}`" v-model="item2.comment" v-if="isEditingThisComment(item2.id)"></textarea>
                        </div>

                        <div v-if="isEditingThisComment(item2.id) === true" class="comment__btn-wrap--edit comment__btns-wrap comment__btns-wrap-edit" style="padding-left: 0; padding-right: 0; padding-bottom: 20px;">
                          <a class="btn-mention" @click.prevent="onTagUser(`[comment-id='${this.getEditSingleComment}' input]`, true)"><i class="fa-solid fa-at"></i>Tag User</a>
                          <a @click.prevent="onCancel()">Cancel</a>
                          <a @click.prevent="onEditComment()">Submit</a>
                        </div>

                      </div>

                </template>

              <!-- ##################################################### CHILD COMMENTS ##################################################### -->

              <!-- ##################################################### REPLY BUTTONS ##################################################### -->


              <div class="comment__input-wrap comment__reply-input" v-if="item.resolved === false && isEditing === false">
                <textarea rows="1" @input="onCommentInputKeyUp(`replyCommentInput--${item.id}`, $event)"  @focus="this.$store.commit('client/setReplyComment', item.id)" type="text" :id="`replyCommentInput--${item.id}`" class="reply-comment-input" placeholder="Write a reply"></textarea>
              </div>
            <div v-if="isEditing === false && item.resolved === false && getReplyComment === item.id" class="comment__btn-wrap" style="padding-left: 17px; padding-right: 17px; padding-bottom: 20px;">
              <a class="btn-mention" @click.prevent="onTagUser(`replyCommentInput--${item.id}`)"><i class="fa-solid fa-at"></i>Tag User</a>
              <a @click.prevent="onCancel()">Cancel</a>
              <a @click.prevent="onReply(item.id)">Submit</a>
            </div>

            <!-- ##################################################### REPLY BUTTONS ##################################################### -->

          </li>


      </ul>
      </div>

       <!-- ##################################################### ADMIN BAR (FILTER) ##################################################### -->

      <div class="comments-list__admin-bar">
          <div class="admin-bar-filter">
            <ul class="filter-menu" v-if="onFilter">
            <li :class="{active : onlyShowResolved}" @click.prevent="onlyShowResolved = !onlyShowResolved">Show Resolved<i v-if="onlyShowResolved" class="fa-duotone fa-check"></i></li>
          </ul>
          <span @click.prevent="onFilter = !onFilter" class="filter"><i class="fa-solid fa-filter"></i> Filter</span>
        </div>
      </div>
    </div>

      <!-- ##################################################### ADMIN BAR (FILTER) ##################################################### -->


  </template>
  
  <script>

import { mapGetters } from 'vuex';
import { Comment } from '../../services/contentNodes';
  
  export default {
    data(){
      return{
        usersArray: [],
			  forceUpdate: 0,
        onlyShowResolved: false,
        onFilter: false,
      }
    },
    computed: {
      ...mapGetters({
        comments: 'client/getClientNodeComments'
      }),
      ...mapGetters("client", ["getCurrentNode", "getClientUsers", "hasCurrentNodeBeenEdited", 'getComment', 'getComments', 'getOpenCommentPanel', "getEditSingleComment", "getReplyComment", 'getTaggedUsers', 'getContentStrategy']),
		  ...mapGetters("auth", ["getAuthUsername"]),
      nestedComments() {
        let nested = [...JSON.parse(JSON.stringify(this.comments))];
        if(this.onlyShowResolved){
          nested = nested.filter(comment => comment.resolved);
        }
        nested.forEach(comment => {
          comment.html = comment.comment.replace(/@(\w+)/g, '<span class="comment-mention">@$1</span>');
        })
        nested.forEach(comment => {
          let user = this.usersArray.find(user => user.username === comment.user);
          for (const key in user) {
            if(key === 'avatarUrl'){
              comment.avatarUrl = user[key];
            }
          }
        })
        nested.forEach(comment => {
          if (comment.parentCommentID) {
            let parent = nested.find(c => c.id === comment.parentCommentID);
            if (parent) {
              parent.replies = parent.replies || [];
              parent.replies.push(comment);
            }
          }
        });
        nested = nested.filter(comment => !comment.parentCommentID);
        nested.forEach(comment => {
          if (comment.replies) {
            comment.replies.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
          }
        });
        nested.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        nested = nested.reverse();

        return nested
      },
      filteredUsersArray() {
        this.forceUpdate;
        let activeElement = document.activeElement;
        if (activeElement && activeElement.tagName === 'TEXTAREA') {
          let inputValue = activeElement.value;
          const atSymbolIndex = inputValue.lastIndexOf('@');
            if (atSymbolIndex === -1) return this.usersArray;
            const searchValue = inputValue.slice(atSymbolIndex + 1);
            return this.usersArray.filter(user => user.username.startsWith(searchValue));
        }else{
          return this.usersArray;
        }
		  },
      isEditing(){
        return this.getEditSingleComment !== '' ? true : false;
      },
    },
    watch:{
      getOpenCommentPanel(val){
        console.log('getOpenCommentPanel watcher:', val);
      },
      'getTaggedUsers': {
        handler(data) {
          if(data.activeComponent === 'commentsList' || data.activeComponent === 'wyswig'){
            if(data.selectedString !== ''){
              document.querySelector(`#${data.lastComponentClicked}`).value += data.selectedString;
              this.$store.commit('client/setTaggedUsers', {prop: 'selected', val: []})
              this.$store.commit('client/setTaggedUsers', {prop: 'selectedString', val: ''})
              this.$store.commit('client/setTaggedUsers', {prop: 'lastComponentClicked', val: false})
            }
          }
        },
        deep: true
      },
    },
    mounted(){
      this.resetFilter();
      this.getUsers();
      this.$store.commit('client/setEditSingleComment', '');
      this.highlightMentions();
    },
    methods: {
      async onEditComment(){
        const editedComment = {
          commentID: this.getEditSingleComment, 
          comment: document.querySelector(`[comment-id="${this.getEditSingleComment}"] textarea`).value
        };
        this.$store.commit("client/setEditComment", editedComment);
        let comment = this.getComments.filter(comment => comment.id === editedComment.commentID);
        try{
          await this.$store.dispatch('client/updateMentions', comment[0]);
          await this.$store.dispatch('client/updateComments');
          this.$store.commit('client/setEditSingleComment', '');
        }catch (error){
          console.log('onAddComment error:', error);
        }
		  },
      async onAddComment(){        
        try{
          let comment = new Comment;
          comment.id = this.getComment.id;
          comment.nodeID = this.getContentStrategy.isShowing ? this.getContentStrategy.currentNode : this.getCurrentNode.id;
          comment.parentCommentID = '';
          comment.user = this.getAuthUsername;
          comment.comment = document.querySelector('#addCommentInput').value;
          console.log('comment add', comment);
          if(document.querySelector('#addCommentInput').value !== ''){
            document.querySelector('#addCommentInput').value = '';
            await this.$store.commit('client/addToClientNodeComments', comment);
            await this.$store.dispatch('client/updateMentions', comment);
            await this.$store.commit('client/setComment', new Comment);
            await this.$store.dispatch('client/updateComments');
          }else{
            alert('A comment cannot be blank');
          }
        }catch (error){
          console.log('onAddComment error:', error);
        }
      },
      highlightMentions() {
        document.querySelectorAll('.comment__content').forEach(content => {
          content.innerHTML = content.innerHTML.replace(/@(\w+)/g, '<span class="comment-mention">@$1</span>');
        });
      },
      onResolve(id){
        this.$store.commit('client/setResolveComment', id);
        this.$store.dispatch('client/updateComments')
		  },
      isEditingThisComment(id){
        if(this.getEditSingleComment === id){
          return true;
        }else{
          return false;
        }
      },
      onCancel(){
        this.$store.commit('client/setCancelComment', true);
        this.$store.commit('client/setEditSingleComment', '');
        this.$store.commit('client/setReplyComment', '');

        document.querySelectorAll('.comments-list textarea').forEach(field => {
          field.value = '';
        })

      },
      onDelete(id){
        this.$store.commit('client/setDeleteComment', id);
        this.$store.dispatch('client/updateComments');
      },
      onOpenMenu(id){
        if(!document.querySelector(`[comment-id="${id}"] .menu`).classList.contains('active')){
          document.querySelector(`[comment-id="${id}"] .menu`).classList.add('active');
        }else{
          document.querySelector(`[comment-id="${id}"] .menu`).classList.remove('active');
        }
      },
      formatDate(dateString){
        let date;
        if(dateString){
            date = new Date(dateString);
        }else{
          date = new Date();
        }
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear().toString().slice(-2);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}  ${hours % 12}:${minutes < 10 ? '0' + minutes : minutes}${ampm}`;

      },
      swapString(myString, myVal, cursorPos) {
        let beforeCursor;
        let afterCursor;
        let newBeforeCursor;
        let newAfterCursor;
        let val;
        let returnData;
        let regex = /@(\w+)$/;
        beforeCursor = myString.slice(0, cursorPos);
        afterCursor = myString.slice(cursorPos);
        //If user types @lu (half way through username)
        if(regex.test(beforeCursor)){
          newBeforeCursor = beforeCursor.replace(/@(\w*)$/, `@${myVal} `);
          newAfterCursor = afterCursor.replace(/^@(\w*)/, `@${myVal} `);
          returnData =  newBeforeCursor + newAfterCursor;
        }else if(beforeCursor.endsWith("@")){
          //If user types @l (just @ symbol)
          val = `${myVal} `;
          beforeCursor = myString.slice(0, cursorPos);
          afterCursor = myString.slice(cursorPos);
          return beforeCursor + val + afterCursor;
        }else{
          //If user clicks tag user button
          val = `@${myVal} `;
          beforeCursor = myString.slice(0, cursorPos);
          afterCursor = myString.slice(cursorPos);
          return beforeCursor + val + afterCursor;
        }
        return returnData;
      },
      // onMention(username, id){
      //   let inputElement = document.querySelector(`#${id}`);
      //   let cursorPos = inputElement.selectionStart;
      //   let newString = this.swapString(inputElement.value, username, cursorPos);
      //   inputElement.value = newString;
      //   inputElement.parentElement.querySelector('.userlist-dropdown').classList.remove('active')
      // },
      async getUsers() {
        this.usersArray = [];
        try {
          this.usersArray = await this.$store.dispatch("user/getAllUsers")
        } catch (error) {
          console.log("oops an error", error)
        }
		  },
      onCommentInputKeyUp(id, event) {
        this.forceUpdate++;
        document.querySelectorAll('.menu').forEach(menu => {
          menu.classList.remove('active');
        })
        if (event.data === '@') {
            // this.$store.commit('client/setTaggedUsers', {prop: 'isEditView', val: isEdit});
            this.$store.commit('client/setTaggedUsers', {prop: 'lastComponentClicked', val: id});
            this.$store.commit('client/setTaggedUsers', {prop: 'activeComponent', val: 'commentsList'});
            this.$store.commit('client/setTaggedUsers', {prop: 'modalIsOpen', val: true});
        }
		  },
      onTagUser(id, isEdit = false){
        this.forceUpdate++;
        this.$store.commit('client/setTaggedUsers', {prop: 'isEditView', val: isEdit});
        this.$store.commit('client/setTaggedUsers', {prop: 'lastComponentClicked', val: id});
        this.$store.commit('client/setTaggedUsers', {prop: 'activeComponent', val: 'commentsList'});
        this.$store.commit('client/setTaggedUsers', {prop: 'modalIsOpen', val: true});
      },
      onFocusComment(id){
        document.querySelectorAll('.comment').forEach(comment => {
          comment.classList.remove('active');
        })
        document.querySelectorAll('.wywig-editor span').forEach(span => {
          span.classList.remove('active');
        })
        if(document.querySelector(`.comment[comment-id="${id}"]`)){
          if(document.querySelector(`.comment[comment-id="${id}"]`).classList.contains('active') == false){
            document.querySelector(`.comment[comment-id="${id}"]`).classList.add('active');
            if(document.querySelector(`.wywig-editor [comment-id="${id}"]`)){
              document.querySelector(`.wywig-editor [comment-id="${id}"]`).classList.add('active');
              document.querySelector(`.wywig-editor [comment-id="${id}"]`).scrollIntoView({ behavior: 'smooth' });
            }
          }
        }
      },
      resetFilter(){
        document.addEventListener('click', (event) => {
          if (!event.target.matches('.filter') && !event.target.matches('.filter-menu')) {
            this.onFilter = false;
          }
          if (!event.target.matches('.btn-mention') && !event.target.matches('.userlist-dropdown')) {
            document.querySelectorAll('.userlist-dropdown').forEach(dropdown => {
              dropdown.classList.remove('active');
            })
          }
          if (!event.target.matches('.wywig-editor__tag-user') && !event.target.matches('.userlist-dropdown--wywig')) {
            document.querySelectorAll('.userlist-dropdown--wywig').forEach(dropdown => {
              dropdown.classList.remove('active');
            })
          }
          if (!event.target.matches('.menu') && !event.target.matches('.fa-ellipsis-vertical') && !event.target.matches('.comment__menu')) {
            document.querySelectorAll('.comment .menu').forEach(menu => {
              menu.classList.remove('active');
            })
          }
        })
      },
      handleContentStrategyClose(){
        this.$store.commit('client/setContentStrategy', {prop: 'currentNode', val: false})
        this.$store.commit('client/setContentStrategy', {prop: 'isCommenting', val: false})
        this.$store.commit('client/setClientNodeComments', []);
      },
      async onReply(parentID){
        if(document.querySelector(`#replyCommentInput--${parentID}`).value !== ''){
            const reply = {
            parentCommentID: parentID, 
            comment: document.querySelector(`#replyCommentInput--${parentID}`).value
          };
          let comment = new Comment;
          comment.id = this.getComment.id;
          comment.nodeID = this.getCurrentNode.id;
          comment.parentCommentID = reply.parentCommentID;
          comment.user = this.getAuthUsername;
          comment.comment = reply.comment;
          try{
            await this.$store.commit('client/addToClientNodeComments', comment);
            await this.$store.dispatch('client/updateMentions', reply);
            await this.$store.commit('client/setComment', new Comment);
            await this.$store.dispatch('client/updateComments');
          }catch (error){
            console.log('onAddComment error:', error);
          }
        }else{
          alert('A comment cannot be blank');
        }
		  },
    }
  };
  </script>



<style lang="scss" scoped>
	@import "../../assets/scss/components/blog.scss";
  .btn-strategy-close{
    margin-left: auto;
    margin-top: 20px;
    margin-right: 20px;
  }
</style>